<template>
  <div class="newscl">
    <!-- header组件     -->
    <vheader :navId="navId"> </vheader>
    <!-- header组件     -->

    <!-- banner组件 -->
    <!-- <banner :navName="navName" :navUrl="navUrl"></banner> -->
    <div class="banner-box">
      <div class="banner-img"></div>
      <div class="banner-nav">
        <div class="banner-nav-content">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <span><i class="el-icon-location"></i>当前位置：</span>
            <el-breadcrumb-item :to="{ path: 'index' }" title="业务入口">网站首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'mediaCoverage' }" title="媒体报道">媒体报道</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'newscl1' }" title="新闻详情">新闻详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <!-- banner组件 -->
    <div class="newscl_w">
      <div class="newsContentBoxLeft">
        <h1 class="newsContentBoxLeftTitle" id="title">来自中关村创业园的一封信——致恒安康泰（北京）医疗健康科技有限责任公司</h1>
        <p class="newsContentBoxLeftTime">
          <span>发布时间：2021-06-09 16:24:55</span><span>阅读：278</span><span>来自：恒安康泰新闻中心</span><span>编辑：刘春梅</span>
        </p>
        <hr class="newsContentBoxLeftFenxian">
        <div id="frameContent">
          <p style="text-align: left;"><span style="font-family: 微软雅黑, ">尊敬的恒安康泰（北京）医疗健康科技的创业者:&nbsp;<br></span></p>
          <p style="line-height: 2em;"><span style="font-size: 16px; font-family: 微软雅黑, ">您好!</span></p>
          <p style="line-height: 2em; text-indent: 2em;"><span
              style="text-indent: 2em; font-family: 微软雅黑, ">欢迎您来到北京市留学人员海淀创业园开始您的创业历程。北京市留学人员海淀创业园(以下简称*海淀创业园”)成立于1997 年
              10
              月,是北京市第一家专门为留学人员回国创业而设立的科技企业孵化器。海淀创业园是科技部认定的国家级留学人员创业园、北京市专业孵化基地、北京市人力资源与社会保障局认定的北京留学人员创业园,是北京市人民政府与国家人社部共建单位。海淀创业园现有孵化场地近
              50000 平方米,包括中关村创业大厦、中关村发展大厦、创业园标厂三座楼宇。海淀创业园自成立以来,吸
              引并培育了一批明星企业和企业家。截至 2017 年 12 月底，创业中心
              累计孵化企业 1500 余家。海淀创业园培育了奥瑞金、启明辰星、软通动力等上市企业 22 家,培育中央“千人计划”46 人、北京市“海聚
              工程”64 人、中关村“高聚工程”27 人,是北京乃至全国高层次人才最为密集的创业园。&nbsp;</span></p>
          <p style="line-height: 2em;"><span style="font-size: 16px; font-family: 微软雅黑, "><br></span></p>
          <p style="line-height: 2em;"><span style="font-size: 16px; font-family: 微软雅黑, ">&nbsp; &nbsp; &nbsp;
              &nbsp;海淀创业园自成立之日起,就以“建设一流创业园，托起明天的太阳“为使命,致力于将自身建设为一所“针对特殊人群,培育明星企业和优秀企业家”的学校。经过多年的努力建设,现已建成了完整的创业孵化体系。同国家科技部、教育部、人社部和北京市相关委、办、局及科技园区管委会有着密切的联系。秉承“热情、周到、专业”的服务理念,海淀创业园积极整合社会资源,为留学人员归国创业提供全方位的服务,与创业业携手共同成长。&nbsp;</span>
          </p>
          <p style="line-height: 2em;"><span style="font-size: 16px; font-family: 微软雅黑, "><br></span></p>
          <p style="line-height: 2em;"><span style="font-size: 16px; font-family: 微软雅黑, ">&nbsp; &nbsp; &nbsp;
              &nbsp;我们将再接再厉,尽我们所能为创业者提供更加贴心的服务,同时, 我们也真诚地希望您对我们的工作给予支持和理解,对我们的工作提出宝贵的建议和意见。&nbsp;</span></p>
          <p style="line-height: 2em;"><span style="font-size: 16px; font-family: 微软雅黑, "><br></span></p>
          <p style="line-height: 2em;"><span style="font-size: 16px; font-family: 微软雅黑, ">&nbsp; &nbsp; &nbsp;
              祝愿您的事业在此腾飞,您的理想在此变为现实！&nbsp;</span></p>
          <p><span style="font-size: 16px; font-family: 微软雅黑, "><br></span></p>
          <p><span style="font-size: 16px; font-family: 微软雅黑, "><br></span></p>
          <p style="text-align: right;"><span style="font-size: 16px; font-family: 微软雅黑, ">中关村科技园区海淀园创业服务中心&nbsp;</span>
          </p>
          <p style="text-align: right;"><span style="font-size: 16px; font-family: 微软雅黑, ">北京市留学人员海淀创业园&nbsp;</span></p>
          <p style="text-align: right;"><span style="font-size: 16px; font-family: 微软雅黑, ">中国北京(海淀)留学人员创业园</span></p>
        </div>
      </div>
      <div class="listNewsRight">
        <h2 class="stateNewsRighth2">健康热点资讯</h2>
        <hr class="stateNewsRight_h2_hr" />
        <h3 class="stateNewsRighth3">
          <a href="" title="国务新闻">国务新闻</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[0]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <!-- <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu1.jpg" alt="" title="恒安康泰医疗" /></a> -->
        <h3 class="stateNewsRighth3">
          <a href="" title="名医出诊">名医出诊</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[1]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康养生">健康养生</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[2]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu2.jpg" alt="" title="恒安康泰医疗" /></a>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康资讯">健康资讯</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[3]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
      </div>
    </div>
    <vfooter></vfooter>
    <GoTop />
  </div>
</template>
<script>
// import banner from "../../components/banner.vue";
import vheader from "../../components/vheader.vue";
import vfooter from "../../components/vfooter.vue";
import GoTop from "@/components/GoTop.vue";
export default {
  name: 'newscl15',
  data() {
    return {
      navName: '新闻详情',
      navUrl: 'newscl15',
      navId: 5,
      rilist1: [
        [
          { listit: "2020年全国居民健康素养水平达23.15%", lishref: "#" },
          { listit: "癌症治疗新希望！我国首个自主研发...", lishref: "#" },
          { listit: "国家卫健委出台人感染动物源性流感...", lishref: "#" },
          { listit: "国家医保局发文，明确推进门诊费用...", lishref: "#" },
          { listit: "国家卫健委：加强儿童中医药服务 推...", lishref: "#" },
          { listit: "这家西南的医院用高品质医疗服务，建...", lishref: "#" },
          { listit: "“十四五”残疾人康复服务蓝图绘就", lishref: "#" },
          { listit: "《2021医生互联网诊疗行为洞察报告》...", lishref: "#" },
          { listit: "六大方面布局！国办发文推动公立医院...", lishref: "#" },
          { listit: "七大城市用户调研指出：智慧医疗未来...", lishref: "#" },
        ],
        [
          { listit: "中国人民解放军总医院院士合集（部分）", lishref: "#" },
          { listit: "U医公益行启动，让泌尿疾病患者在基层...", lishref: "#" },
          { listit: "第十八期“院士+”西部儿科医师培训班座...", lishref: "#" },
          { listit: "福建首例机器人无腹部切口大肠癌手术在...", lishref: "#" },
          { listit: "多位专家赴湖北十堰参加燃气爆炸紧急医...", lishref: "#" },
          { listit: "张楠：阿尔茨海默病患者护理更需专业指导", lishref: "#" },
          { listit: "第一届智慧医疗与医学人文高峰论坛暨医...", lishref: "#" },
          { listit: "提醒身边长痣的朋友，出现以下五种症状...", lishref: "#" },
          { listit: "“疫”路同行的夫妻档", lishref: "#" },
          { listit: "与北京社团合作，中国西部城市乌兰察布...", lishref: "#" },
        ],
        [
          { listit: "水培蔬菜社交网络走红 专家：较土培更...", lishref: "#" },
          { listit: "山药的做法 山药的功效与作用", lishref: "#" },
          { listit: "发青的鸡蛋黄致癌？ 变绿部分无毒，可...", lishref: "#" },
          { listit: "经常失眠，可能是体内缺什么？3种营养...", lishref: "#" },
          { listit: "你知道健康吃饭的黄金标准吗？", lishref: "#" },
          { listit: "5大习惯能『防癌』，很多人都忽视了", lishref: "#" },
          { listit: "每餐4-6小时的间隔期最符合人体消化需...", lishref: "#" },
          { listit: "晨起一杯水别这么喝，风险太大...教你...", lishref: "#" },
          { listit: "心脏有问题，为什么医生还建议让我多运...", lishref: "#" },
          { listit: "缺铁性贫血，补就完事？补血食物别踩坑", lishref: "#" },
        ],
        [
          { listit: "疾控专家邵一鸣对我国目前疫苗功效给出解读", lishref: "#" },
          {
            listit: "馒头、米饭哪个更容易升血糖？一对比，3餐...",
            lishref: "#",
          },
          { listit: "手抖也能通过外科手术治愈啦", lishref: "#" },
          { listit: "身体血糖高 多喝水好还是少喝水好？看完...", lishref: "#" },
          { listit: "练起来 打太极拳能减“游泳圈”", lishref: "#" },
          { listit: "【科学就医我行动】陈勇皮科问答 | 10.皮...", lishref: "#" },
          { listit: "维生素C有什么功效和副作用？", lishref: "#" },
          {
            listit: "“提高免疫力”，被过度夸大化，免疫平衡比“...",
            lishref: "#",
          },
          { listit: "胃癌如何选择最合适疗方案？CA最新刊文指...", lishref: "#" },
          { listit: "九价HPV疫苗为何“一针难求”", lishref: "#" },
        ],
      ],
    };
  },
  components: {
    // banner,
    vheader,
    vfooter,
    GoTop,
  },
};
</script>
<style lang="less">
@media screen and (min-width:750px) {
  .banner-box {
    .banner-img {
      height: 280Px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 100%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 1200Px;
        margin: auto;

        .el-breadcrumb {
          line-height: 70Px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 20Px;
            margin-right: 12Px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}

@media screen and (max-width:750px) {
  .banner-box {
    .banner-img {
      height: 110px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 200%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 95%;
        margin: auto;

        .el-breadcrumb {
          line-height: 35px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 19px;
            margin-right: 2px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}
</style>